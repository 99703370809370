export default function ChatFlair({
        text,
        bgcolor
    }) {

    return (<>
             <div className="flex-0 mr-0.5 ">
                <FlairPill
                    text={text}
                    color={bgcolor}h-3
                />
            </div>
    </>)
}

export function FlairPill({text,color}) {
    return (<>
              <div 
                title={text}
                className={`
                        inline
                            ${color} text-white  px-1 py-0.5 rounded-sm text-3xs font-base`}>
                {text}
              </div>
            </>)
  }
  