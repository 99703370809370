import {   menuaccountlist } from "@/lib/config"

import { Fragment, useContext, useEffect, useRef, useState } from "react"
import Popup from "reactjs-popup"
import PopupMenuOptionLink from "../popup-menu-option-link"
import MenuButton from "./menu-button"
import { signIn } from "next-auth/client"
import AvatarFlair from "../user/avatar-flair"
import { TribeContext } from "@/components/context/tribe-wrapper"
import AvatarFlairClean from "../user/avatar-flair-clean"

function MenuAccountDropdown({
     loading
    ,session
    ,userAuthData
    ,isUserAuthLoading
    ,isUserAuthError
    ,handleLogoutClick
    ,navtype
    ,open
    ,set_open
}) {


  const [isMounted,set_isMounted] = useState(false)

  useEffect(() => {
    set_isMounted(true)
  }, [])

  // const ref = useRef(null);
  // const closeMenu = () => {if (ref.current) ref.current.close()};

    const avatar = <AvatarFlairClean   
                    isPlaying={false}
                    isContributor={userAuthData?.contributor_level > 0}
                    avatar_url={userAuthData?.avatar_url}
                    avatar_url_sfw={userAuthData?.avatar_url_sfw}
                    bol_nsfw={userAuthData?.bol_nsfw}
                    avatar_crdate={userAuthData?.avatar_crdate}
                    id={userAuthData?.id}
                    user_name={userAuthData?.user_name}
                    bgClass='w-8 h-8'
                    containerClass='w-8 h-7 -mt-[2px]'
                    avatarClass={`w-8 h-8 border-2 ${userAuthData?.contributor_level > 0 ? "border-white  bg-gray-700" : "border-gray-700  bg-gray-700"} `}
                    rotaryContainerClass='w-8 h-8 mr-4'
                    rotaryInternalClass='w-8 h-9'
                    sizes={{
                      width:36
                     ,height:36
                     ,quality:50
                    }}
                  />

    const PopupTrigger =   <div onClick={()=>set_open('account')}>
                            <MenuButton
                              open={open}
                              session={session}
                              loading={loading}
                              avatar={<>{avatar}</>}
                              
                            />
                          </div>

return (
    <div className="menu flex items-center content-center  flex-0 z-50">
    
    {!isMounted || !session &&
    <>{PopupTrigger}</>
    }

    {isMounted && session &&
    <>
    <Popup
      position="bottom left"
      open={open}
      onOpen={()=>set_open('account')}
      //onClose={()=>set_open('')}
      trigger={PopupTrigger}
      //on="click"
      closeOnDocumentClick
      closeOnEscape
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent', zIndex:1999 }}
      arrow={false}
      nested
      //ref={isMounted ? ref : null}
    >
                  <div className="menu ring-0 fixed top-[47px] right-[0px] w-full xs:w-64 ">
                    
                          <div className="menu flex flex-col gap-y-1 rounded-b-md shadow-md p-2 border-x border-b
                                        bg-gray-800 border-gray-700"
                          >
                            <div className="flex items-center content-center px-1 pb-1 rounded-b-md font-bold text-md
                                bg-gray-800">
                              <div
                                className="flex-1 text-sm"
                              >
                                {session && !isUserAuthLoading && `Hi ${userAuthData?.user_name}!`}
                                {(loading || isUserAuthLoading) && `Just a sec!`}
                                {!loading && !session && `Join us!`}
                              </div>
                              {/* <div className="flex-0">
                                {avatar}
                              </div> */}
                            </div>

                            {(loading || isUserAuthLoading) &&
                            <>
                              <div className="p-4">
                                Still Loading...
                              </div>
                            </>
                            }
                           
                            {!(loading || isUserAuthLoading) && session &&
                            <AccountOptions
                              user_name={userAuthData?.user_name}
                              navtype={navtype}
                              handleLogoutClick={handleLogoutClick}
                            />
                            }

                          {!(loading || isUserAuthLoading) && !session &&
                            <NoAccountOptions
                              user_name={'there'}
                              navtype={navtype}
                            />
                            }

                        </div>

             
                            
                    </div>
    </Popup>
    </>
      }
    

  </div>
  );

 
}

function AccountOptions({
  user_name
  ,navtype
  ,handleLogoutClick
}) {

  
  const ssr_data_tribe = useContext(TribeContext)

  return (<>
    {menuaccountlist(user_name).map((e,index) => 
    <Fragment key={index}>
    {(
          ["contribute"].indexOf(e?.type) == -1
          || (["contribute"].indexOf(e?.type) > -1 && ssr_data_tribe?.bol_contribute == "1")
         ) &&
      <PopupMenuOptionLink
        // onClick={()=>{
        //   closeMenu();
        //   router.push(permaurl);
        // }}
        icon={e.icon}
        iconclass={e.iconclass}
        name={e.name}
        type={e.type}
        url={e.url}
        behavior={e.behavior}
        navtype={navtype}
      />
    }
    </Fragment>
    )}
    <PopupMenuOptionLink
      onClick={()=>handleLogoutClick("menu")}
      icon={"logout-sm"}
      iconclass={"w-5 h-5"}
      name={"Logout"}
      type={"logout"}
      url={""}
      navtype={navtype}
      behavior={"button"}
    />
  </>)
}

function NoAccountOptions({
  user_name
  ,navtype
}) {
  return (<>
    <PopupMenuOptionLink
      onClick={()=>{}}
      icon={"profile-sm"}
      iconclass={"w-5 h-5"}
      name={"Create an account"}
      type={"register"}
      url={"/app/auth/register"}
      navtype={navtype}
      behavior={"link"}
    />
    <PopupMenuOptionLink
      onClick={signIn}
      icon={"profile-sm"}
      iconclass={"w-5 h-5"}
      name={"Login to an account"}
      type={"login"}
      url={""}
      navtype={navtype}
      behavior={"button"}
    />
  </>)
}

export default MenuAccountDropdown