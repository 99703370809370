
import { signIn } from "next-auth/client";
import Button from "../button/button";
import SvgJsx from "../svg/svg-jsx";

function MenuButton({
     open
    ,avatar
    ,session
    ,loading
}) { 

    if (!session ) {
      return (<>
        <MenuButtonWrapper>
            <Button 
              className=''
              onClick={signIn}
            >
                      <div
                          className={`flex-0 flex items-center content-center rounded-md shadow-md border border-gray-600 hover:border-white text-white hover:animate-pulse `}>
                        <div className="flex-1 rounded-l-md">
                          <div className={` 

                            cursor-pointer text-center 
                            pl-2 pr-2 xs:pr-1 py-1 flex font-bold text-md
                            
                            
                            `}
                          >
                            <SvgJsx 
                              type={"fill"}
                              icon={"group-sm"}
                              className={`h-4  w-4 flex mr-1 `}
                              title={"Create or Go Live"}
                            />
                          </div>
                        </div>
                        <div 
                        className="flex-0 cursor-pointer hidden xs:block
                           pr-1 py-1 xs:pr-3 rounded-r-md 
                          "
                        >
                          Join Us
                        </div>
                        
                      </div>
          </Button>
        
                         
        </MenuButtonWrapper> 
      </>)
    }


    return (<>
                <MenuButtonWrapper>
                            
                            <div className='
                            '>

                              {avatar}
                             
                            </div>
                            
                            
                          
                          
            </MenuButtonWrapper> 

    </>)
}

function MenuButtonWrapper({children}) {
  return (<>
      <div>
        <div 
          className={`flex flex-none items-center content-center cursor-pointer whitespace-nowrap border group  p-0.5 rounded-md text-sm font-medium 
          
          text-white   hover:text-white
        border-transparent
          `}
            
          id="main-menu" 
          aria-haspopup="true"
        >
         {children}                 
        </div>
      </div>
  
  </>)
}

export default MenuButton