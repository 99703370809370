
import * as React from "react";
import { useEffect, useState } from "react";
import { hasData } from "@/lib/utils/hasData";
import WizardHeader from "@/components/templateux/wizard/wizard-header";
import isAdminUser from "@/lib/utils/isAdminUser";
import WizardSectionForm from "@/components/templateux/wizard/wizard-section-form";
import WizardOwner from "@/components/templateux/wizard/wizard-owner";
import { sluginput } from "@/lib/utils/slugify";
import WizardPager from "@/components/templateux/wizard/wizard-pager";
import { truncate } from "@/lib/utils/truncate";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { ifNull } from "@/lib/utils/ifNull";
import { tablehelptype } from "@/lib/config";

type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  processing?: number;
  set_processing?: any;
  set_changes?: any;
  changes?: any;
  max_files?: any;

  //soundboard info
  soundboard_id?: any;
  itemdata?: any;
  parentdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?:any;
  soundboardcache?:any;
  set_soundboardcache?:any;
  typevalue?:any;
  set_typevalue?:any;

  //inherited info
  ownerdata?: any;
  userdata?: any;

  navtype?: string;
  helptype?: string;
  
  clip_state?: any;
  clip_json?: any;
}

function SoundboardForm({
  //submitting
   submitHandler
  ,submitting
  ,set_submitting
  ,processing
  ,set_processing
  ,set_changes
  ,changes
  ,max_files

  //soundboard info
  ,soundboard_id
  ,itemdata
  ,parentdata
  ,itemdataisLoading
  ,itemdataisError
  ,show_graphic
  ,form_reset
  ,set_form_reset
  ,actionText
  ,soundboardcache
  ,set_soundboardcache
  
  //inherited info
  ,ownerdata
  ,userdata
  ,navtype
  ,helptype

  ,clip_state="0"
  ,clip_json=[]
}: Props) {


  const [ispageloaded,set_ispageloaded] = useState(false)
  useEffect(()=>{set_ispageloaded(true)},[])
 
  const [admin_user_name,set_admin_user_name] = useState(!itemdataisLoading 
                                                          && !itemdataisError 
                                                          && itemdata 
                                                          && ifNull(itemdata?.user_name,ownerdata?.user_name))
  const [bol_admin_user_name,set_bol_admin_user_name] = useState("0")
  const [user_id,set_user_id] = useState(userdata?.id)
  
  const [_soundboard_title, set_soundboard_title] = useState(ifNull(itemdata?.soundboard_title,''))
  const [_soundboard_description, set_soundboard_description] = useState(ifNull(itemdata?.soundboard_description,''))
  const [_soundboard_tags, set_soundboard_tags] = useState(ifNull(itemdata?.soundboard_tags,''))
  const [_bol_public, set_bol_public] = useState(ifNull(itemdata?.bol_public,'1'))

  const [_access_level, set_access_level] = useState(ifNull(itemdata?.access_level,'1'))
  const [_slug, set_slug] = useState(ifNull(itemdata?.slug,''))
  const [_soundboard_json, set_soundboard_json] = useState(ifNull(itemdata?.soundboard_json,'[]'))
  
  useEffect(()=>{


    if (form_reset==1 
      && ((
          hasData(soundboard_id) 
          && soundboard_id.toString() !== "0" 
          && !itemdataisLoading && !itemdataisError
          )
        )
      ) {

      set_admin_user_name(hasData(itemdata?.user_name) 
                          ? itemdata?.user_name 
                          : userdata?.user_name)


        set_soundboard_title(hasData(itemdata?.soundboard_title)
                                    ? itemdata?.soundboard_title 
                                    : ''
                                  )
        set_soundboard_description(hasData(itemdata?.soundboard_description) 
                                    ? itemdata?.soundboard_description 
                                    : ''
                                    )
        set_soundboard_tags(hasData(itemdata?.soundboard_tags)
                                    ? itemdata?.soundboard_tags 
                                    : ''
                                  )

      set_bol_public(hasData(itemdata?.bol_public) 
                                  ? itemdata?.bol_public 
                                  : '1'
                                  )

        set_access_level(hasData(itemdata?.access_level) 
                                  ? itemdata?.access_level 
                                  : '1')

        set_slug(hasData(itemdata?.slug) 
                                  ? itemdata?.slug 
                                  : '')

        if (clip_state?.status?.toString()=="0" || !hasData(clip_state)) {
          
          set_soundboard_json(hasData(itemdata?.soundboard_json) 
                                  ? itemdata?.soundboard_json 
                                  : '[]')
        }

        set_form_reset(0) //make sure we dont remove the data again now that it's filled in.
    
    }
 
},[
   itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,soundboard_id
  ,changes
  ,form_reset
  ,set_form_reset
  ,userdata
  ,clip_state
])


useEffect(()=> {

  if (clip_json?.length > 0) {
    set_soundboard_json(JSON.stringify([{
      url: clip_json[0]?.url,
      hash: clip_json[0]?.hash,
      size: clip_json[0]?.size,
      crdate: clip_json[0]?.crdate,
      source: clip_json[0]?.source,
      duration: clip_json[0]?.duration,
      filename: clip_json[0]?.filename,
      extention: clip_json[0]?.extention,
      post_type: clip_json[0]?.post_type,
      upload_id: clip_json[0]?.upload_id,
      metadata_json: clip_json[0]?.metadata_json,
      clip_start: clip_json[0]?.clip_start,
      clip_end: clip_json[0]?.clip_end
    }]))
  }

},[
  clip_json
])


  const { isConfirmed } = useConfirm();

  async function reset_soundboard () {
    if (JSON.parse(_soundboard_json)?.length > 0) {
      const confirmed = await isConfirmed("Changing your soundboard will reset everything. Are you sure?");
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
     if (confirmed) {
        set_soundboard_json('[]')
      }
    }
  }

  function slugformat(value) {
    if (value?.length > 0) {
      set_slug(truncate(sluginput(soundboard_id,value,value).replace(`${soundboard_id}-`,''),25,'')?.replace(`Untitled`,'')?.toLowerCase())
    } else {
      set_slug('')
    }
  }

  const [wizard_id,set_wizard_id] = useState(0);
  const wizard_array = [
    {
      title:'Soundboard content'
      ,description:''
      ,short: 'content'
      ,fields: [
                {
                  type:'text'
                  ,title:'Soundboard Title'
                  ,description:''
                  ,helptip:''
                  ,name:'soundboard_title'
                  ,placeholder:''
                  ,value:_soundboard_title
                  ,set_value:set_soundboard_title
                },
                
                
                 {
                    type: 'textarea-autosize'
                    ,title:'Soundboard Description'
                    ,description:''
                    ,helptip:''
                    ,name:'soundboard_description'
                    ,placeholder:''
                    ,value:_soundboard_description
                    ,set_value:set_soundboard_description
                  },
                {
                  type:'text'
                  ,title:'Soundboard Tags'
                  ,description:'separate tags with commas'
                  ,helptip:''
                  ,name:'soundboard_tags'
                  ,placeholder:''
                  ,value:_soundboard_tags
                  ,set_value:set_soundboard_tags
                },
                


        // {
        //   type:'select'
        //   ,title:'Who can use this soundboard?'
        //   ,description:''
        //   ,helptip:''
        //   ,name:'access_level'
        //   ,placeholder:''
        //   ,options:[
        //     {
        //       text:'Everyone'
        //       ,value: '0'
        //     },
        //     {
        //       text:'Registered Members'
        //       ,value: '1'
        //     },
        //     {
        //       text:'Contributors Only'
        //       ,value: '2'
        //     },
        //   ]
        //   ,value:_access_level
        //   ,set_value:set_access_level
        // },
        // {
        //   type:'text'
        //   ,title:'Customize your URL Ending'
        //   ,description:<div className='text-xs'>{ssr_data_tribe?.server_website}/{admin_user_name}/soundboard/{(soundboard_id?.toString()=="0" ? "42069" : soundboard_id?.toString())}-<span className='text-blue-400'>{sluginput((soundboard_id==0 ? 42069 : soundboard_id),_slug,_soundboard_title)}</span></div>
        //   ,helptip:''
        //   ,name:'slug'
        //   ,placeholder:`${sluginput(soundboard_id,_slug,_soundboard_title).replace(`${soundboard_id}-`,'')}`
        //   ,value:_slug
        //   ,set_value:slugformat
        //   ,meta:{maxChars:25}
        // },
        
        {
          type:'selectoptions'
          ,title:'Who can use this soundboard?'
          ,description:''
          ,helptip:''
          ,name:'bol_public'
          ,placeholder:''
          ,value:_bol_public
          ,set_value:set_bol_public
          ,options:[
            {
              text:'Only me'
              ,value: '-1'
            },{
              text:'Everyone'
              ,value: '0'
            },
            {
              text:'All Members'
              ,value: '1'
            },
            {
              text:'All Contributors'
              ,value: '2'
            }
          ]
        }
        
      ]
    }
  ]



          



    const [isAdmin,set_isAdmin] = useState(isAdminUser(
       ownerdata?.id?.toString()          //user_id
      ,ownerdata?.bol_staff_help    //bol_staff_help
      ,ownerdata?.staff_json        //staff_json
      ,ownerdata?.bol_admin_help    //bol_admin_help
      ,userdata?.id?.toString()                            //my_id
      ,userdata?.bol_admin?.toString()              //global_admin
      ,tablehelptype('soundboard')
  ))
   




  function handleSubmit(e) {

    e.preventDefault();


      const soundboard_data = {
          soundboard_id: soundboard_id
        , soundboard_parent_id: parentdata?.soundboard_id
        , soundboard_title: _soundboard_title
        , soundboard_description: _soundboard_description
        , soundboard_tags: _soundboard_tags
        , bol_public: _bol_public
        , access_level: _access_level
        , slug: hasData(_slug) ? _slug : `${sluginput(soundboard_id,_slug,_soundboard_title).replace(`${soundboard_id}-`,'')}`
        , soundboard_json: _soundboard_json 
        , admin_help_user_id: user_id
        , set_submitting
      } 



      submitHandler(soundboard_data
      ,`/api/private/soundboard/update`
      ,'POST'
      ,{
        'Content-Type': 'application/json',
      }
      ,'Your changes were saved'
    )
    set_changes(false);
    let cachedate = + new Date;
    set_soundboardcache(cachedate?.toString())

    
  }
  

  return (
    <>
    {/* info COMPONENT */}
    <div className="w-full">

      
{/* 
      <WizardHeader
        wizard_array={wizard_array}
        wizard_id={wizard_id}
        set_wizard_id={set_wizard_id}
        disable_mobileview={true}
      /> */}
    
    {/* {clip_state?.status?.toString() == "2" &&
        <>
          <div className="mt-2 font-bold text-lg">
          Your clip is processing now. 
          </div>
          <div className="italic">
          Add a little more info about it and then continue to the next section.
          </div>
          <ul>
            <li className="list-disc ml-5">
              Clipped from: <Link href={`/${parentdata?.user_name?.toLowerCase()}/posts/${slugify(parentdata?.soundboard_id,parentdata?.soundboard_title)}`}>
                <a className="underline hover:no-underline" target="_blank">
                  {parentdata?.soundboard_title}
                </a>
              </Link>
            </li>
          </ul>
          

        </>
    } */}

      {/* FORM */}
      <div>
      <form 
        className="space-y-6" 
        onSubmit={handleSubmit}
      >

        {/* {JSON.stringify(_publish_date)}
        <div>
        {isostringifyDateObject(_publish_date)}
        </div>*/}
        {/* <div>
          {_soundboard_json}
        </div>  */}
        {/* <div>
          {JSON.parse(_soundboard_json)?.length}
        </div> */}
       

        <WizardSectionForm
          section_id={0}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        ><>
        
        </></WizardSectionForm> 

        <WizardSectionForm
          section_id={1}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
          ><>
          
          </></WizardSectionForm> 

        <WizardSectionForm
          section_id={2}
          wizard_id={wizard_id}
          set_wizard_id={set_wizard_id}
          wizard_array={wizard_array}
          submitting={submitting}
          set_submitting={set_submitting}
          processing={processing}
          set_processing={set_processing}
          set_changes={set_changes}
          changes={changes}
        >



                <WizardOwner
                  isAdmin={isAdmin}
                  bol_admin_user_name={bol_admin_user_name}
                  set_bol_admin_user_name={set_bol_admin_user_name}
                  admin_user_name={admin_user_name}
                  set_admin_user_name={set_admin_user_name}
                  user_id={user_id}
                  set_user_id={set_user_id}
                  submitting={submitting}
                  set_submitting={set_submitting}
                  processing={processing}
                  set_processing={set_processing}
                  changes={changes}
                  set_changes={set_changes}
                  title={`Set a Soundboard Owner`}
                  description={``}
                />


    </WizardSectionForm>  

    <WizardPager
      wizard_id={wizard_id}
      set_wizard_id={set_wizard_id}
      submitting={submitting}
      changes={changes}
      actionText={actionText}
      wizard_array={wizard_array}
    />
 
        
      </form>
    </div>
    {/* SET referral FORM */}

    </div>
    {/* // referral SET COMPONENT */}
  </>
  )
}

export default SoundboardForm



